import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const VpsSupportPage = () => (
  <div>
      <SEO 
        title="VPS Packages Constant Client Support Service" 
        description="You will be able to enjoy the attributes of a qualified round the clock client help service with all of the VPS hosting plans."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title">VPS Packages <span className="block text-indigo-600 xl:inline">Constant Client Support</span> Service<span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              You will be able to enjoy the attributes of a qualified round the clock client help service with all of the VPS hosting plans.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Assistance Channels
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/ticket-response-time.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A one hour Inquiry Solution Warranty</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Our crew of specialists will do their best to respond to your service tickets within about 1 hour. If, however, 
                        the difficulty you have stumbled upon requires the help of the Admin =Department,= we are going to address the 
                        ticket to the technician in charge and will write a comment to notify you.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/quality-support-service.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Top–quality Tech Assistance Service</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        We are online for you day and night to provide you with useful information and technical support and 
                        also to help you with any trouble that you may run into as you are controlling your virtual server.
                        We will support all installed in advance packages, such as your Operating System, server 
                        administration panel (with regards to the VPS type) and the Web Control Panel designed 
                        completely by us.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/help-center.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Descriptive Help Section</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        In the Web Control Panel that is offered totally free with the virtual server, you’ll discover an 
                        interesting Help section, consisting of all you should be informed about when working with your 
                        web pages and programs. We have introduced a wide selection of how–to articles and video 
                        tutorials that show the fundamental web site managing responsibilities in an easy to 
                        read fashion.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default VpsSupportPage
